import React from 'react';
import styles from './Stakeholdercard.module.css'; 
import edit from '../../assets/icons/svg/edit.svg';
import remove from '../../assets/icons/svg/delete.svg';
import more from '../../assets/icons/svg/more1.svg'
import { axiosPrivateCall, apiCall } from '../../constants';
import { ToastContainer, toast } from 'react-toastify';

function StakeholderCard(props)  {
    let data = props.data;

    function formatDate(inputDateString) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const inputDate = new Date(inputDateString);
        return inputDate.toLocaleDateString('en-US', options);
    }


    function delete_item (id) {
        let payload = {_id: id}

        apiCall(
            "post",
            `/api/v1/stakeholder/deleteStakeHolder`,
            payload,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
                toast.success("Stakeholder Deleted Successfully!");
                props.setsudoUpdate((prev) => !prev)
            }
          });

    }


    return (
        <div className={`${styles.container}`}>

        <div className={`${styles.left_container}`}>
            <div className={`${styles.data}`}>
                <div className={`${styles.data_title}`}>Stakeholder ID</div>
                <div className={`${styles.data_value}`}>{data.stake_holder_id}</div>
            </div>
        </div>

        


        <div className={`${styles.mid_container}`}>

            <div className={`${styles.sub_container_2}`} >
                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>Company Name</div>
                    <div title={`${data.company_name}`} className={`${styles.data_value}`}>{data.company_name}</div>
                </div>

                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>Email</div>
                    <div title={`${data.email}`} className={`${styles.data_value}`}>{data.email}</div>
                </div>

                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>Mobile</div>
                    <div className={`${styles.data_value}`}>{data.mobile}</div>
                </div>

                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>Total Stages</div>
                    <div className={`${styles.data_value_level}`}>
                        
                        {data.stages.map((level) => 
                            <div className={`${styles.level}`}></div>
                        )}

                        {data.stages.length}
                    </div>
                </div>

            </div>
            
            
        </div>

        <div className={`${styles.right_container}`}>
            <div className={`${styles.buttons_container}`}>
                <div className={`${styles.demand_buttons}`}
                onClick={() => {props.setEditModal({show: true, data: data})}}
                >
                    <img src={edit}/>
                </div>
                <div className={`${styles.demand_buttons}`} 
                onClick={() => {delete_item(data._id)}}
                >
                    <img src={remove}/>
                </div>
                <div className={`${styles.demand_buttons}`}><img src={more}/></div>

            </div>

        </div>

    </div>

    );
};

export default StakeholderCard;