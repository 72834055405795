import React from "react";
import styles from "./Sidebar.module.css";


import Sidebutton from "./Sidebutton";
import home from "../../assets/icons/svg/home.svg";
import demand from "../../assets/icons/svg/thunder.svg";
import candidate from "../../assets/icons/svg/candidate.svg";
import submission from "../../assets/icons/svg/submission.svg";
import agent from "../../assets/icons/svg/agent.svg";
import reports from "../../assets/icons/svg/reports.svg";
import employee from "../../assets/icons/svg/employee.svg";
import stakeholder from "../../assets/icons/svg/stakeholder.svg";
import settings from "../../assets/icons/svg/settings.svg";
import signout from "../../assets/icons/svg/signout.svg";
import { useNavigate } from "react-router-dom";
import Logoutbutton from "./Logoutbutton";


function Sidebar() {

   

    return (
        <nav className={`${styles.container}`} >
            <div className={`${styles.top_section}`}>
                {/* <Sidebutton icon={home} to="/"/> */}
                <Sidebutton icon={demand} to="/demand" title='Demand'/>
                <Sidebutton icon={candidate} to="/candidate" title='Candidate'/>
                <Sidebutton icon={submission} to="/submission" title='Submission'/>
                <Sidebutton icon={agent} to="/agent" title='Agent'/>
                {/*<Sidebutton icon={reports} to="/reports"/> */}
                <Sidebutton icon={employee} to="/employee" title='Employee'/>
                <Sidebutton icon={stakeholder} to="/stakeholder" title='Stake Holder'/>
            </div>

            <div className={`${styles.bottom_section}`}>
                {/* <Sidebutton icon={settings} to="/settings"/> */}
                <Logoutbutton icon={signout} title='Logout' />
            </div>

        </nav>
    );
}

export default Sidebar;