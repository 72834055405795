import React from 'react';
import styles from './Loader.module.css'; // You can create a separate CSS file for styling

const Loadersmall = () => {
  return (
    <div className={styles.loader_container}>
      <div className={styles.loader_small}></div>
    </div>
  );
};

export default Loadersmall;