import React, { useEffect, useState } from "react";
import styles from "./Statscardlight.module.css"
import Progressbar from "../progressbar/Progressbar";
import cup from "../../assets/icons/svg/cup.svg";
import dataStore from "../../store/store"

function Statscardlight() {
    const {aggregate_data} = dataStore();

    const [totDemand, setTotDemand] = useState(0);
    const [totFulDemand, setFulTotDemand] = useState(0);

   

    useEffect(() => {
        setTotDemand(aggregate_data?.total_demands);
        setFulTotDemand(aggregate_data?.total_demands_fulfilled);
    },[aggregate_data]);

    


    return (
        <div className={`${styles.card_light}`}>
            <div className={`${styles.top_container}`} >
                <div className={`${styles.title_container}`}>Demand Stats</div>
                {/* <div className={`${styles.action_container}`}>
                    <div className={`${styles.button_wrapper} ${styles.button_container}`}>This week</div>
                    <div className={`${styles.button_wrapper} ${styles.arrow_container}`}><img src={arrowdown}/></div>
                </div> */}

            </div>

            <div className={`${styles.progress_wrapper}`} >
                <Progressbar title={'Total progress'} progress={Math.ceil((totFulDemand / totDemand) * 100) || 0 } showMarking={true} showLevel={true} icon={cup}/>
            </div>

            <div className={`${styles.bottom_container}`}>
                <div className={`${styles.stat_data_container}`}>
                    <div className={`${styles.title_wrapper}`}>Total Demands Assigned</div>
                    <div className={`${styles.stat_container}`}>#{totDemand || 0}</div>
                </div>

                <div className={`${styles.stat_data_container}`}>
                    <div className={`${styles.title_wrapper}`}>Total Demands Fulfilled</div>
                    <div className={`${styles.stat_container}`}>#{totFulDemand || 0}</div>
                </div>

                <div className={`${styles.stat_data_container} ${styles.design_wrapper}`}>
                    <div className={`${styles.title_wrapper}`}>Average Fulfillment</div>
                    <div className={`${styles.stat_container}`}>{Math.ceil((totFulDemand / totDemand) * 100) || 0}%</div>
                    <div className={`${styles.concentric_pattern}`}>
                        <div className={`${styles.concentric_outer}`}></div>
                        <div className={`${styles.concentric_center}`}></div>
                        <div className={`${styles.concentric_inner}`}></div>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default Statscardlight;