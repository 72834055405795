import React from 'react';
import styles from './Employeecard.module.css'; 
import edit from '../../assets/icons/svg/edit.svg';
import remove from '../../assets/icons/svg/delete.svg';
import more from '../../assets/icons/svg/more1.svg'
import { axiosPrivateCall, apiCall } from '../../constants';
import { ToastContainer, toast } from 'react-toastify';
import dataStore from "../../store/store";






function EmployeeCard(props)  {
    let data = props.data;

    function formatDate(inputDateString) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const inputDate = new Date(inputDateString);
        return inputDate.toLocaleDateString('en-US', options);
    }

    const {current_user, employee} = dataStore();

    console.log(current_user)

  

    function delete_item (id) {
        let payload = {employee_id: id}

        apiCall(
            "post",
            `/api/v1/employee/deleteEmployee`,
            payload,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
                toast.success("Employee Deleted Successfully!");
                props.setsudoUpdate((prev) => !prev)
            }
          });

    }

    return (
        <div className={`${styles.container}`}>

        <div className={`${styles.left_container}`}>
            <div className={`${styles.data}`}>
                <div className={`${styles.data_title}`}>Employee ID</div>
                <div className={`${styles.data_value}`}>{data.employee_id}</div>
            </div>
        </div>

        


        <div className={`${styles.mid_container}`}>

            <div className={`${styles.sub_container_2}`} >
                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>User Name</div>
                    <div title={`${data.user_name}`} className={`${styles.data_value}`}>{data.user_name}</div>
                </div>

                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>User Role</div>
                    <div title={`${data.user_role}`} className={`${styles.data_value}`}>{data.user_role}</div>
                </div>

                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>Reports To</div>
                    <div title={`${employee?.filter((emp) => (emp._id === data.reports_to))[0]?.user_name || 'Matrix'}`} className={`${styles.data_value}`}>{employee?.filter((emp) => (emp._id === data.reports_to))[0]?.user_name || 'Matrix'}</div>
                </div>

                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>Email</div>
                    <div title={`${data.email}`} className={`${styles.data_value}`}>{data.email}</div>
                </div>

            </div>
            
            
        </div>

        { <div className={`${styles.right_container}`}>
            { (<div className={`${styles.buttons_container}`}>
                <div className={`${styles.demand_buttons}`}
                onClick={() => {props.setEditModal({show: true, data: data})}}
                >
                    <img src={edit}/>
                </div>
                <div className={`${styles.demand_buttons}`} 
                onClick={() => {delete_item(data._id)}}
                >
                    <img src={remove}/>
                </div>
                <div className={`${styles.demand_buttons}`}><img src={more}/></div>

            </div>)}

        </div>}

    </div>

    );
};

export default EmployeeCard;