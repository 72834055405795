import React, { useState } from 'react';
import styles from './Checkbox.module.css';

const Checkbox = (props) => {

  let setData = props.setData;

  function toggleChecked(id) {
    setData(prevData => {
      // Create a copy of the data
      return prevData.map(category => {
        // Check if the category contains the item with the given id
        const updatedItems = category.items.map(item => 
          item.jsUserId === id ? { ...item, checked: !item.checked } : item
        );
        // Return updated category
        return { ...category, items: updatedItems };
      });
    });

  };


  return (
    <label className={`${styles.container}`}>
      <input type="checkbox" checked={props.checked} onChange={() => {toggleChecked(props.userId)}} />
      <svg viewBox="0 0 64 64" height="16px" width="16px">
        <path
          d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
          pathLength="575.0541381835938"
          className={`${styles.path}`}
        ></path>
      </svg>
    </label>
  );
};

export default Checkbox;
