import React from "react";
import styles from "../modals.module.css"
import arrowright1 from "../../../assets/icons/svg/arrowright1.svg"
import { useState, useEffect } from "react";
import DynamicFormGenerator from "../../dynamicformgenerator/Dynamicformgenerator";
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivateCall, apiCall } from "../../../constants";
import dataStore from "../../../store/store";
import Loadersmall from '../../loader/Loadersmall';


function Create(props) {

  

  const formFields = [
    { name: 'demand', label: 'Demands', type: 'searchable', placeholder: 'Enter Demand ID', isRequired: true },
    { name: 'candidate', label: 'Candidates', type: 'searchinput', placeholder: 'Enter Candidate ID', isRequired: true },
  ];
      const [formState, setFormState] = useState({});
      const [errors, setErrors] = useState({});
      const [isLoading, setIsLoading] = useState(false);
      const {stakeholders} = dataStore();


      const handleInputChange = (fieldName, value) => {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: '',
        }));
      };
    
      function validateField(fieldName, value) {
        let errorMessage = '';
      
        if (fieldName === 'current_ctc' || fieldName === 'minimum_experience' || fieldName === 'maximum_experience' || fieldName === 'no_of_positions' ) {
          errorMessage = isNaN(parseFloat(value.trim())) ? "Invalid input. Kindly enter a valid numeric value." : '';
        } 

        if (fieldName === 'skillset') {
          errorMessage = (value.length) ? '' : 'Required';
        }
      
        return errorMessage;
      }

      const validateForm = (formTemplate, formState, setErrors) => {
       
        let newErrors = {};
    
        formTemplate.forEach((field) => {
          if (field.isRequired && (formState[field?.name] === '' || formState[field?.name] === undefined)) {
            newErrors[field.name] = 'Required';
          } else {
            newErrors[field.name] = validateField(field.name, formState[field?.name]);
          }
        });
    
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
        }));

        return newErrors;
      
      };
      
      function isFormValid () {
        // Use await to wait for the promise returned by validateForm
        const updatedErrors = validateForm(formFields, formState, setErrors);
      
        // Check if there are no errors in the updated errors object
        return Object.values(updatedErrors).every(error => error === '');
      };


    

      const handleSubmit = () => {
        if (isFormValid()) {
          setIsLoading(true)
          let payload = {...formState};

          apiCall(
            "get",
            `/api/v1/demand/getDemandDetails?demand_id=${formState?.demand}`,
            null,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
              let buffer = res?.stake_holder;
              payload.progress = stakeholders.filter((obj) => (obj._id === buffer))[0].stages;
              payload.current_progress = " ";

              apiCall(
                "post",
                `/api/v1/submission/createSubmission`,
                payload,
                {},
                axiosPrivateCall
              ).then((res) => {
                if (res) {
                  toast.success("Submission Created Successfully!");
                  props.onClose();
                  props.setsudoUpdate((prev) => !prev);
                }
              });
            }
          });

        } else {
          // Form is not valid, handle accordingly (display errors, etc.)
          console.log('Form validation failed.', formState, errors);
        }
      };

      



    return (
        <div className={`${styles.container}`}>
          <div className={`${styles.title_container}`}>
              <div className={`${styles.line_1}`}>
                  CREATE A
              </div>

              <div className={`${styles.line_2_wrapper}`}>
                  <div className={`${styles.line_2}`}>
                      SUBMISSION
                  </div>
              </div>
          </div>

          <div className={`${styles.form_container}`}>
              <DynamicFormGenerator formFields={formFields} 
                onInputChange={handleInputChange} 
                formData={formState} 
                setFormData={setFormState}
                errors={errors} 
              />
          </div>


          <div className={`${styles.submit_container}`}>
              <div className={`${styles.button_1}`} onClick={handleSubmit}>
                  {!isLoading && <div>SUBMIT</div>}
                  {!isLoading && <img src={arrowright1}></img>}
                  {isLoading && <Loadersmall/>}
              </div>

              <div className={`${styles.button_2}`} onClick={props.onClose}>
                  CANCEL
              </div>
              
          </div>
            
           
            
        </div>
    );
}

export default Create;