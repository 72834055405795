import React, { useState, useEffect } from "react";
import styles from "./Searchbar.module.css";
import { ReactComponent as SearchIcon } from "../../assets/icons/svg/search.svg";
import useDebounce from '../../hooks/useDebounce';

function Search({setListing, fields, setisLoader1, data }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { debounce } = useDebounce();



  function fullTextSearch(data, searchTerm, fields) {
    // Convert search term to lowercase for case-insensitive search
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
  
    // Perform the search
    const results = data.filter(item => {
      return fields.some(field => {
        const fieldValue = item[field];
        if (fieldValue && typeof fieldValue === 'string') {
          // Convert field value to lowercase for case-insensitive comparison
          const lowerCaseFieldValue = fieldValue.toLowerCase();
  
          // Check if the search term is present in the field value
          return lowerCaseFieldValue.includes(lowerCaseSearchTerm);
        }
        return false;
      });
    });
  
    setListing(results)
  }
  
  


  

    
  
  const handleInputChange = debounce((newValue) => {
    // Perform some time-consuming operation with the debounced value
    fullTextSearch(data, newValue, fields);
  }, 500);
  

  

  // Handle input change
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    handleInputChange(event.target.value);
  };

  return (
    <div className={`${styles.searchbar_container}`}>
      <SearchIcon className={styles.search_icon} />
      <input
        type="text"
        className={styles.search_input}
        placeholder="Type here to search"
        value={searchTerm}
        onChange={handleChange}
      />
    </div>
  );
}

export default Search;