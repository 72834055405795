import React from 'react';
import styles from './Candidatecard.module.css'; 
import Skillcard from '../skillcard/Skillcard';
import Progressbar from '../progressbar/Progressbar';
import clock from '../../assets/icons/svg/clock.svg';
import location from '../../assets/icons/svg/location.svg';
import go from '../../assets/icons/svg/go.svg';
import edit from '../../assets/icons/svg/edit.svg';
import remove from '../../assets/icons/svg/delete.svg';
import more from '../../assets/icons/svg/resume.svg'
import { axiosPrivateCall, apiCall } from '../../constants';
import { ToastContainer, toast } from 'react-toastify';
import dataStore from '../../store/store';




function CandidateCard(props)  {
    let data = props.data;
    const {employee} = dataStore();

    function formatDate(inputDateString) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const inputDate = new Date(inputDateString);
        return inputDate.toLocaleDateString('en-US', options);
    }

    function delete_item (id) {
        let payload = {_id: id}

        apiCall(
            "post",
            `/api/v1/candidate/deleteCandidate`,
            payload,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
                toast.success("Candidate Deleted Successfully!");
                props.setsudoUpdate((prev) => !prev)
            }
          });

    }

    function openResume(url) {
        var link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const getNoticePeriod = (str) => {
        const notice_obj = {
            "15 Days or less" : "< 15 Days",
            "To be modified" : "TBD",
            "1 Month": "30 Days"
        }

        const notice = notice_obj[str]
        if(notice){
            return notice 
        }else{
            return str + " Days"
        }
    }

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.left_container}`}>
                <div className={`${styles.data_container}`}>
                    <div className={`${styles.data}`}>
                        <div className={`${styles.data_title}`}>
                            Candidate ID
                        </div>
                        <div title={`${data.candidate_id}`} className={`${styles.data_value}`}>
                            {data.candidate_id}
                        </div>
                    </div>

                    <div className={`${styles.data}`}>
                        <div className={`${styles.data_title}`}>
                            Current CTC
                        </div>
                        <div title={`${data.current_ctc}`} className={`${styles.data_value}`}>
                            {data.current_ctc}
                        </div>
                    </div>

                    <div className={`${styles.data}`}>
                        <div className={`${styles.data_title}`}>
                            Sourced By
                        </div>
                        <div title={`${employee.filter((obj) => (data?.created_by === obj._id))[0]?.user_name}`} className={`${styles.data_value}`}>
                            {employee.filter((obj) => (data?.created_by === obj._id))[0]?.user_name}
                        </div>
                    </div>
                </div>

                <div className={`${styles.concentric_pattern}`}>
                    <div className={`${styles.concentric_outer}`}></div>
                    <div className={`${styles.concentric_center}`}></div>
                    <div className={`${styles.concentric_inner}`}></div>
                </div>
            </div>

            <div className={`${styles.mid_container}`}>

                <div className={`${styles.mid_top_name_container}`}>
                    <div className={`${styles.mid_top_name_data}`}>
                        <div className={`${styles.data_title}`}>
                            Name 
                        </div>

                        <span title={ (data?.first_name) ? `${data?.first_name} ${data?.last_name}` : `${data?.full_name}` } className={`${styles.data_value}`}>
                            {(data?.first_name) ? `${data?.first_name} ${data?.last_name}` : data?.full_name } 
                        </span>
                    </div>

                </div>

                <div className={`${styles.mid_top_container}`}>
                    <div className={`${styles.mid_top_data}`}>
                        <div className={`${styles.data_title}`}>
                            Current Role
                        </div>

                        <div title={`${data?.employment_details[0]?.role}`} className={`${styles.data_value}`}>
                            {data?.employment_details[0]?.role}
                        </div>

                    </div>

                    <div className={`${styles.mid_top_data}`}>
                        <div className={`${styles.data_title}`}>
                            Current Company
                        </div>

                        <div title={`${data?.employment_details[0]?.company_name}`} className={`${styles.data_value}`}>
                            {data?.employment_details[0]?.company_name}
                        </div>

                    </div>

                </div>

                <div className={`${styles.mid_bottom_container}`}>
                    <div className={`${styles.mid_tile_container}`}>
                        <div className={`${styles.mid_tile} ${styles.mid_icon}`}><img src={clock}/></div>
                        <div className={`${styles.mid_tile}`}>{getNoticePeriod(data.notice_period)}</div>
                    </div>

                    <div className={`${styles.mid_tile_container}`}>
                        <div className={`${styles.mid_tile} ${styles.mid_icon}`}><img src={location}/></div>
                        <div className={`${styles.mid_tile}`}>{data.current_location}</div>
                    </div>
                </div>

            </div>

            <div className={`${styles.right_container}`}>
                <div className={`${styles.sub_container_1}`}>
                    <div className={`${styles.experience_container}`}>
                        <div className={`${styles.experience_tile} `}>
                            <span>Total Exp</span> <span className={`${styles.data_value}`}>{data.total_exp} Years</span>
                        </div>
                    </div>

                    <div className={`${styles.skill_container}`}>
                        <Skillcard skills={data.skillset}/>
                    </div>
                    
                </div>

                <div className={`${styles.sub_container_2}`}>
                    <div className={`${styles.buttons_container}`}>
                        <div className={`${styles.demand_buttons}`}
                        onClick={() => {props.setEditModal({show: true, data: data})}}
                        >
                            <img src={edit}/>
                        </div>
                        <div className={`${styles.demand_buttons}`} 
                        onClick={() => {delete_item(data._id)}}
                        >
                            <img src={remove}/>
                        </div>
                        <div className={`${styles.demand_buttons}`} onClick={() => openResume(data?.resume_url)}><img src={more}/></div>

                    </div>

                    <div className={`${styles.progress_card}`}>
                        <div className={`${styles.progress_top}`}>
                            <div className={`${styles.progress_title}`}>Utilization Percentage</div>
                            <div className={`${styles.progress_icon}`} ><img src={go}/></div>
                        </div>

                        <div className={`${styles.progressbar}`}><Progressbar progress={26}/></div>

                        <div className={`${styles.progress}`}>26 %</div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default CandidateCard;