import React from "react";
import styles from "./Bargraph.module.css";
import { motion } from "framer-motion";

function Bargraph(props) {

    let color = props.color;
    let progress = props.progress;
    let primaryStat = props.primaryStat;
    let secondaryStat = props.secondaryStat;

    return(
        <div className={`${styles.container}`}>
            {primaryStat && <div className={`${styles.top_container} ${styles.stat_text}`}>
                {primaryStat}
            </div>}

            {secondaryStat && <div className={`${styles.center_container} ${styles.stat_text}`}>
                {secondaryStat}
            </div>}
            
            <div className={`${styles.graph_container}`}>
                <div className={`${styles.base}`}></div>
                <motion.div className={`${styles.graph}`} 
                style={{height:`${progress}%`, backgroundColor: color}}
                initial={{ height: 0, }}
                animate={{ opacity: 1, height: `${progress}%` }}
                transition={{ duration: 1 }}></motion.div>
                
            </div>
        </div>
    );

};

export default Bargraph;