import * as tf from '@tensorflow/tfjs';
import { pipeline } from '@xenova/transformers';
import { env } from '@xenova/transformers';

env.allowLocalModels = false;
env.useBrowserCache = false;

class ProfileRanker {
    constructor() {
        this.embeddingsCache = {};
    }

    async initializeModel() {
        this.extractor = await pipeline('feature-extraction', 'Xenova/all-MiniLM-L6-v2');
    }

    async _getEmbedding(text) {
        const embeddings = await this.extractor([text], { pooling: 'mean', normalize: true });
        return embeddings.tolist()[0]; // Get the first embedding array
    }

    async _getBatchEmbeddings(texts) {
        const embeddings = await Promise.all(texts.map(text => this._getEmbedding(text)));
        return embeddings;
    }

    async _getBatchSimilarityScore(text1List, text2List) {
        const embeddings1 = await this._getBatchEmbeddings(text1List);
        const embeddings2 = await this._getBatchEmbeddings(text2List);

        // Ensure all embeddings have the same length
        const embeddingLength = embeddings1[0]?.length;
        const embeddings1Tensor = tf.tensor2d(embeddings1, [embeddings1?.length, embeddingLength]);
        const embeddings2Tensor = tf.tensor2d(embeddings2, [embeddings2?.length, embeddingLength]);

        const similarityScores = tf.matMul(embeddings1Tensor, embeddings2Tensor, false, true);
        return similarityScores.arraySync();
    }

    async calculateTitlesSimilarity(profileTitles, idealProfileTitles) {
        const simScores = await this._getBatchSimilarityScore(profileTitles, idealProfileTitles);
        return simScores.map(scores => Math.max(...scores));
    }

    async calculateSkillScore(requiredSkills, preferredSkills, profileSkills) {
        const uniqueProfileSkills = Array.from(new Set(profileSkills.flat()));
        const uniqueSkills = Array.from(new Set([...requiredSkills, ...preferredSkills]));
        const allSkillScores = await this._getBatchSimilarityScore(uniqueProfileSkills, uniqueSkills);

        const skillPairScores = {};
        uniqueProfileSkills.forEach((profileSkill, i) => {
            uniqueSkills.forEach((skill, j) => {
                skillPairScores[`${profileSkill}-${skill}`] = allSkillScores[i][j];
            });
        });

        const calculateSkillScoreArray = (skills, skillSet) => {
            return skills.map(profileSkills => {
                return skillSet.reduce((acc, skill) => {
                    return acc + Math.max(...profileSkills.map(profileSkill => skillPairScores[`${profileSkill}-${skill}`] || 0));
                }, 0) / skillSet.length;
            });
        };

        const reqSkillScores = calculateSkillScoreArray(profileSkills, requiredSkills);
        const prefSkillScores = calculateSkillScoreArray(profileSkills, preferredSkills);

        return [reqSkillScores, prefSkillScores];
    }

    calculateExperienceScore(minExperience, maxExperience, profileExperience) {
        const profileExp = profileExperience.years + (profileExperience.months / 12);
        const minExp = minExperience || 0;
        const maxExp = maxExperience || 100;
        return minExp <= profileExp && profileExp <= maxExp ? 1 : 0;
    }

    async calculateLocationScore(jobLocations, profilesLocation, preferredLocations) {
        const uniqueLoc = Array.from(new Set([...preferredLocations.flat(), ...profilesLocation]));
        const allLocScores = await this._getBatchSimilarityScore(jobLocations, uniqueLoc);

        const locPairScores = {};
        jobLocations.forEach((jobLoc, i) => {
            uniqueLoc.forEach((loc, k) => {
                locPairScores[`${jobLoc}-${loc}`] = allLocScores[i][k];
            });
        });

        const curLocScores = profilesLocation.map(loc => Math.max(...jobLocations.map(jobLoc => locPairScores[`${jobLoc}-${loc}`] || 0)));
        const prefLocScores = preferredLocations.map(locs => Math.max(...locs.map(loc => Math.max(...jobLocations.map(jobLoc => locPairScores[`${jobLoc}-${loc}`] || 0)))));

        return curLocScores.map((score, i) => Math.max(score, prefLocScores[i]));
    }

    calculateCtcScore(minCtc, maxCtc, profileCtc) {
        maxCtc = Math.max(maxCtc, minCtc);
        return profileCtc <= maxCtc ? 1 : 0;
    }

    async score(jobSpecification, profiles, weightage, progressCallback) {
        const profilesTitle = profiles.map(p => p.jobTitle);
        const idealProfileTitles = Object.values(jobSpecification.ideal_candidate_profile);
        const requiredSkills = jobSpecification.required_skills;
        const preferredSkills = jobSpecification.preferred_skills;
        const jobLocations = jobSpecification.locations;

        const profilesSkills = profiles.map(p => p.keySkills.split(','));
        const profilesLocation = profiles.map(p => p.currentLocation);
        const preferredLocations = profiles.map(p => p.preferredLocations.split(','));

        const profilesTitleScore = await this.calculateTitlesSimilarity(profilesTitle, idealProfileTitles);
        const [requiredSkillsScore, preferredSkillsScore] = await this.calculateSkillScore(requiredSkills, preferredSkills, profilesSkills);
        const locationsScore = await this.calculateLocationScore(jobLocations, profilesLocation, preferredLocations);

        const maxPossibleScore = Object.values(weightage).reduce((acc, val) => acc + val, 0);
        const rankedProfiles = profiles.map((profile, i) => {
            const experienceScore = this.calculateExperienceScore(jobSpecification.minimum_experience, jobSpecification.maximum_experience, profile.experience);
            const ctcScore = this.calculateCtcScore(jobSpecification.min_ctc || 0, jobSpecification.max_ctc || 0, parseFloat(profile.ctcinfo.lacs));

            const totalScore = weightage.profile_title * profilesTitleScore[i] +
                               weightage.required_skills * requiredSkillsScore[i] +
                               weightage.preferred_skills * preferredSkillsScore[i] +
                               weightage.experience * experienceScore +
                               weightage.location * locationsScore[i] +
                               weightage.ctc * ctcScore;

            const normalizedScore = (totalScore / maxPossibleScore) * 10;
            // console.log(`Scoring Progress: ${Math.min((i + 1) / profiles.length * 100, 100).toFixed(2)}% (${i + 1}/${profiles.length})`);
            return { [profile.jsUserId]: parseFloat(normalizedScore.toFixed(3)) };
        });

        for (let i = 0; i < profiles.length; i++) {
            progressCallback(Math.min((i + 1) / profiles.length * 100, 100).toFixed(2));
            await new Promise((resolve) => setTimeout(resolve, 10)); // Simulate async processing
        }


        return rankedProfiles.sort((a, b) => Object.values(b)[0] - Object.values(a)[0]);
    }
}

export default ProfileRanker;
