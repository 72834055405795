import React, { useEffect } from "react";
import { useState, useRef } from "react";
import styles from "./Demandcard.module.css";
import Skillcard from "../skillcard/Skillcard";
import Progressbar from "../progressbar/Progressbar";
import clock from "../../assets/icons/svg/clock.svg";
import location from "../../assets/icons/svg/location.svg";
import arrowright1 from "../../assets/icons/svg/arrowright1.svg";
import go from "../../assets/icons/svg/go.svg";
import edit from "../../assets/icons/svg/edit.svg";
import remove from "../../assets/icons/svg/delete.svg";
import more from "../../assets/icons/svg/more1.svg";
import { axiosPrivateCall, apiCall } from "../../constants";
import { ToastContainer, toast } from "react-toastify";
import dataStore from "../../store/store";

function DemandCard(props) {
  let data = props.data;

  const { employee, stakeholders } = dataStore();
  const [showOptions, setShowOptions] = useState(false);
 

 

  function deployAutopilot () {
    // window.postMessage({ action: "", payload: data }, "*");

    const event = new CustomEvent("Sh-Base-Msg", {
      detail: {action: "AUTO_PILOT", payload: data}
    });
    document.dispatchEvent(event);

    setShowOptions(!showOptions)

  
  };

  // function deployAutoPilot(id) {
  //   let [buffer] = demandList.filter((demand) => demand._id === id);
  //   let payload = {};
  //   payload.key_words= {text: `${buffer.job_title}`};
  //   payload.minimum_experience = {text : buffer.minimum_experience / 12};
  //   payload.maximum_experience = {text : buffer.maximum_experience / 12};
  //   payload.requiredCount = buffer.no_of_positions;
  //   buffer.skillset.map((data) => {payload.key_words.text = `${payload.key_words.text}, ${data.skill}`});
  //   console.log(payload);
  //   window.postMessage({ action: 'engage', payload}, '*');
  //   // console.log(buffer);

  //   // chrome.runtime.sendMessage({ action: "openNaukriPage" });
  // }

  

  function formatDate(inputDateString) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const inputDate = new Date(inputDateString);
    return inputDate.toLocaleDateString("en-US", options);
  }

  function delete_item(id) {
    let payload = { _id: id };

    apiCall(
      "post",
      `/api/v1/demand/deleteDemand`,
      payload,
      {},
      axiosPrivateCall
    ).then((res) => {
      if (res) {
        toast.success("Demand Deleted Successfully!");
        props.setsudoUpdate((prev) => !prev);
      }
    });
  }

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.left_container}`}>
        <div className={`${styles.data_container}`}>
          <div className={`${styles.data}`}>
            <div className={`${styles.data_title}`}>Demand ID</div>
            <div title={`${data.demand_id}`} className={`${styles.data_value}`}>
              {data.demand_id}
            </div>
          </div>

          <div className={`${styles.data}`}>
            <div className={`${styles.data_title}`}>Current CTC</div>
            <div
              title={`${data.current_ctc}`}
              className={`${styles.data_value}`}
            >
              {data.current_ctc}
            </div>
          </div>

          <div className={`${styles.data}`}>
            <div className={`${styles.data_title}`}>Assigned To</div>
            <div
              title={`${
                employee.filter((obj) => data?.assigned_to === obj._id)[0]
                  ?.user_name
              }`}
              className={`${styles.data_value}`}
            >
              {
                employee.filter((obj) => data?.assigned_to === obj._id)[0]
                  ?.user_name
              }
            </div>
          </div>
        </div>

        <div className={`${styles.concentric_pattern}`}>
          <div className={`${styles.concentric_outer}`}></div>
          <div className={`${styles.concentric_center}`}></div>
          <div className={`${styles.concentric_inner}`}></div>
        </div>
      </div>

      <div className={`${styles.mid_container}`}>
        <div className={`${styles.mid_top_container}`}>
          <div className={`${styles.mid_top_data}`}>
            <div className={`${styles.data_title}`}>Name Of the Role</div>

            <div title={`${data?.job_role}`} className={`${styles.data_value}`}>
              {data?.job_role}
            </div>
          </div>

          <div className={`${styles.mid_top_data}`}>
            <div className={`${styles.data_title}`}>Name Of the Company</div>

            <div
              title={`${
                stakeholders.filter(
                  (obj) => data?.stake_holder?._id === obj._id
                )[0]?.company_name
              }`}
              className={`${styles.data_value}`}
            >
              {
                stakeholders.filter(
                  (obj) => data?.stake_holder?._id === obj._id
                )[0]?.company_name
              }
            </div>
          </div>
        </div>

        <div className={`${styles.mid_bottom_container}`}>
          <div className={`${styles.mid_tile_container}`}>
            <div className={`${styles.mid_tile} ${styles.mid_icon}`}>
              <img src={clock} />
            </div>
            <div className={`${styles.mid_tile}`}>
              {formatDate(data.due_date)}
            </div>
          </div>

          <div className={`${styles.mid_tile_container}`}>
            <div className={`${styles.mid_tile} ${styles.mid_icon}`}>
              <img src={location} />
            </div>
            <div className={`${styles.mid_tile}`}>{data?.location[0]}</div>
          </div>
        </div>
      </div>

      <div className={`${styles.right_container}`}>
        <div className={`${styles.sub_container_1}`}>
          <div className={`${styles.experience_container}`}>
            <div className={`${styles.mid_tile} ${styles.experience_tile}`}>
              {data.minimum_experience} Years
            </div>
            <div className={`${styles.experience_icon}`}>
              <img src={arrowright1} />
            </div>
            <div className={`${styles.mid_tile} ${styles.experience_tile}`}>
              {data.maximum_experience} Years
            </div>
          </div>

          <div className={`${styles.skill_container}`}>
            <Skillcard skills={data.skillset} />
          </div>
        </div>

        <div className={`${styles.sub_container_2}`}>
          <div className={`${styles.buttons_container_wrapper}`}>
            <div className={`${styles.buttons_container}`}>
              <div
                className={`${styles.demand_buttons}`}
                onClick={() => {
                  props.setEditModal({ show: true, data: data });
                }}
              >
                <img src={edit} />
              </div>
              <div
                className={`${styles.demand_buttons}`}
                onClick={() => {
                  delete_item(data._id);
                }}
              >
                <img src={remove} />
              </div>
              <div className={`${styles.demand_buttons}`} onClick={() => {setShowOptions(!showOptions)}}>
                <img src={more} />
              </div>
            </div>

            {showOptions ? (<div className={`${styles.more_options}`} >
              <div onClick={deployAutopilot} className={`${styles.option}`}>Go AutoPilot</div>
            </div>) : null}
          </div>

          <div className={`${styles.progress_card}`}>
            <div className={`${styles.progress_top}`}>
              <div className={`${styles.progress_title}`}>Demand progress</div>
              <div className={`${styles.progress_icon}`}>
                <img src={go} />
              </div>
            </div>

            <div className={`${styles.progressbar}`}>
              <Progressbar progress={26} />
            </div>

            <div className={`${styles.progress}`}>26 %</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemandCard;
