import React, { useEffect, useState } from "react";
import styles from "./Orgselector.module.css";
import arrow from "../../assets/icons/svg/arrowright.svg";
import signout from "../../assets/icons/svg/signout.svg";
import { axiosAuthCall, apiCall } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import Logoutbutton from "../../components/sidebar/Logoutbutton";

function Orgselector({ sessionUser, setLoggedIn, getAppData }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const auth_token = localStorage.getItem("auth_token");

  useEffect(() => {
    let user_data = extractPayload(localStorage.getItem("auth_token"));

    if (searchParams.get("invite_code")) {
      let payload = {};
      payload.invite_code = searchParams.get("invite_code");
      payload.user_name = user_data.user_name;

      if (auth_token) {
        setIsLoading(true);
        apiCall(
          "post",
          "/api/v1/org/AcceptOrgInvite",
          payload,
          {},
          axiosAuthCall
        ).then((res) => {
          navigate("/");
          setIsLoading(false);
          window.location.reload();
        });
        console.log("call from useEffect");
      }
    }
  }, []);

  function extractPayload(jwt) {
    const parts = jwt.split(".");
    if (parts.length !== 3) {
      throw new Error("Invalid JWT format");
    }

    const encodedPayload = parts[1];
    const decodedPayload = atob(encodedPayload);
    const payloadObject = JSON.parse(decodedPayload);

    return payloadObject;
  }

  let organizations = [...sessionUser?.user_orgs];

  const [i, setI] = useState(0);

  const handleLeftClick = () => {
    setI((prevCounter) => Math.max(prevCounter - 1, 0));
  };

  const handleRightClick = () => {
    setI((prevCounter) => Math.min(prevCounter + 1, organizations.length - 1));
  };

  const handleCreateOrg = () => {
    // Handle creating a new organization
    console.log("Create organization clicked");
  };

  const selectOrg = () => {
    setIsLoading(true);
    apiCall(
      "post",
      "/api/v1/user/signInOrg",
      {
        org: organizations[i]?.org._id,
        org_code: organizations[i]?.employee_id.slice(0, 3),
      },
      {},
      axiosAuthCall
    ).then((res) => {
      localStorage.setItem("token", res?.token);
      getAppData();
      setLoggedIn(true);
      setIsLoading(false);
      // navigate("/demand");
      
      const event = new CustomEvent("Sh-Base-Msg", {
        detail: {action: "TOKEN", payload: res?.token}
      });
      document.dispatchEvent(event);
    });
  };

  return (
    <>
      {isLoading && <Loader/>}
      {!isLoading && <div className={`${styles.container}`}>
        
        <div className={`${styles.organizations_list}`}>
        
          {organizations.length > 0 ? (
            <div className={`${styles.sub_wrap}`}>
              <h2>Your Organizations</h2>
              <div className={`${styles.carousel}`}>
                {organizations.length === 1 ? null : (<div
                  className={`${styles.carousel_button} ${styles.invert}`}
                  onClick={handleLeftClick}
                >
                  <img src={arrow} />
                </div>)}

                <div
                  className={`${styles.organization_card}`}
                  onClick={selectOrg}
                >
                  <div className={styles.logo_wrapper}>
                    <img
                      src={organizations[i]?.org?.logo}
                      className={styles.logo}
                    />
                  </div>
                  <div className={styles.name_wrapper}>
                    {organizations[i]?.org?.name}
                  </div>
                </div>

                {organizations.length === 1 ? null : (<div
                  className={`${styles.carousel_button}`}
                  onClick={handleRightClick}
                >
                  <img src={arrow} />
                </div>)}
              </div>
            </div>
          ) : (
            <div>
              <h2>Wait for Invite</h2>
              <p>
                You don't belong to any organizations yet. Wait for an invitation
                to join or create a new organization.
              </p>
            </div>
          )}
        </div>
        <div className={`${styles.create_organization}`}>
          <div className={`${styles.button_1}`} onClick={handleCreateOrg}>
            Create Organization
          </div>
          <Logoutbutton icon={signout}/>
        </div>
      </div>}
    </>
  );
}

export default Orgselector;
