import React from "react";
import styles from "./Login.module.css";
import Signin from "./Signin";
import Signup from "./Signup";
import Orgselector from "./Orgselector";

function Login({
  loggedIn,
  setLoggedIn,
  isNewUser,
  setIsNewUser,
  sessionUser,
  setSessionUser,
  getAppData,
}) {
  return (
    <div className={`${styles.root_container}`}>
      {isNewUser ? (
        <Signup setIsNewUser={setIsNewUser} />
      ) : sessionUser.logged_in ? (
        <Orgselector
          sessionUser={sessionUser}
          setLoggedIn={setLoggedIn}
          getAppData={getAppData}
        />
      ) : (
        <Signin setIsNewUser={setIsNewUser} setSessionUser={setSessionUser} />
      )}
    </div>
  );
}

export default Login;
