import React from "react";
import styles from "../modals.module.css"
import arrowright1 from "../../../assets/icons/svg/arrowright1.svg"
import { useState, useEffect } from "react";
import DynamicFormGenerator from "../../dynamicformgenerator/Dynamicformgenerator";
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivateCall, apiCall } from "../../../constants";
import Loadersmall from '../../loader/Loadersmall';


function Create(props) {

  

    const formFields = [
      { name: 'user_role', label: 'Role', type: 'select', placeholder: 'Enter user role', isRequired: true },
      { name: 'reports_to', label: 'Reports To', type: 'searchable', placeholder: 'Enter reports to', isRequired: true },
    ];
      const [formState, setFormState] = useState({});
      const [errors, setErrors] = useState({});
      const [isLoading, setIsLoading] = useState(false);
      const [inviteLink, setInviteLink] = useState('');

    
      const handleInputChange = (fieldName, value) => {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: '',
        }));
      };
    
      function validateField(fieldName, value) {
        let errorMessage = '';
      
        if (fieldName === 'current_ctc' || fieldName === 'minimum_experience' || fieldName === 'maximum_experience' || fieldName === 'no_of_positions') {
          errorMessage = isNaN(parseFloat(value.trim())) ? "Invalid input. Kindly enter a valid numeric value." : '';
        }
      
        if (fieldName === 'skillset') {
          errorMessage = value.length ? '' : 'Required';
        }
      
      
        return errorMessage;
      }
      

      const validateForm = (formTemplate, formState, setErrors) => {
       
        let newErrors = {};
    
        formTemplate.forEach((field) => {
          if (field.isRequired && (formState[field?.name] === '' || formState[field?.name] === undefined)) {
            newErrors[field.name] = 'Required';
          } else {
            newErrors[field.name] = validateField(field.name, formState[field?.name]);
          }
        });
    
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
        }));

        return newErrors;
      
      };
      
      function isFormValid () {
        // Use await to wait for the promise returned by validateForm
        const updatedErrors = validateForm(formFields, formState, setErrors);
      
        // Check if there are no errors in the updated errors object
        return Object.values(updatedErrors).every(error => error === '');
      };

      function extractPayload(jwt) {
        const parts = jwt.split('.');
        if (parts.length !== 3) {
          throw new Error('Invalid JWT format');
        }
      
        const encodedPayload = parts[1];
        const decodedPayload = atob(encodedPayload);
        const payloadObject = JSON.parse(decodedPayload);
      
        return payloadObject;
      }
      
    
      const handleSubmit = () => {
        if (isFormValid()) {
          setIsLoading(true)

          let payload = {...formState};
          let token_data = extractPayload(localStorage.getItem("token"));
          payload.org = token_data.org;
          payload.org_code = token_data.org_code;

          apiCall(
            "post",
            `/api/v1/org/sendOrgInvite`,
            payload,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
              setInviteLink(`https://app.scouthawk.in?invite_code=${res?.invite_code}`);
              setIsLoading(false)
            }
          });

        } else {
          // Form is not valid, handle accordingly (display errors, etc.)
          console.log('Form validation failed.', formState, errors);
        }
      };



    return (
        <div className={`${styles.container}`}>
          <div className={`${styles.title_container}`}>
              <div className={`${styles.line_1}`}>
                  ADD AN
              </div>

              <div className={`${styles.line_2_wrapper}`}>
                  <div className={`${styles.line_2}`}>
                      EMPLOYEE
                  </div>
              </div>
          </div>

          <div className={`${styles.form_container}`}>
              <DynamicFormGenerator formFields={formFields} 
                onInputChange={handleInputChange} 
                formData={formState} 
                setFormData={setFormState}
                errors={errors} 
              />
              <div>
                <div className={`${styles.field_label}`}>Invite Link</div>
                <textarea
                  className={`${styles.textarea}`}
                  placeholder={'Generate an invite to add user'}
                  value={inviteLink}
                />
            </div>
          </div>

          

          <div>

          </div>

          


          <div className={`${styles.submit_container}`}>
              <div className={`${styles.button_1}`} onClick={handleSubmit}>
                  {!isLoading && <div>GENERATE</div>}
                  {!isLoading && <img src={arrowright1}></img>}
                  {isLoading && <Loadersmall/>}
              </div>

              <div className={`${styles.button_2}`} onClick={() =>{props.setsudoUpdate((prev) => !prev); props.onClose()}}>
                  CLOSE
              </div>
              
          </div>
            
           
            
        </div>
    );

    }
export default Create;