import React from "react";
import styles from "./Layoutmodal.module.css"



function Layoutmodal(props) {
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.modal_negative}`}>

            </div>
            <div className={`${styles.modal_wrapper}`}>
                {props.children}
            </div>
        </div>
    );
}

export default Layoutmodal;