import React, { useRef, useEffect, useState } from 'react';
import styles from './Skillcard.module.css'; // Replace with the correct path to your CSS module file






function Skillcard(props)  {
  const containerRef = useRef(null);
  const [maxSkillsToShow, setMaxSkillsToShow] = useState(4);
  const skillsList = [...props.skills];

  useEffect(() => {
    // Assuming the parent div has dimensions 300px by 200px (adjust as needed)
  
    // const parentDivHeight = containerRef.current.clientHeight;

    // // Assuming each skill item has a height of 20px (adjust based on your styling)
    // const skillItemHeight = 16;

    // // Calculate the maximum number of skills to show based on the available space
    // const maxSkills = Math.floor(parentDivHeight / skillItemHeight);
    // setMaxSkillsToShow(maxSkills - 2);
  }, []);

  const remainingSkillsCount = Math.max(0, skillsList.length - maxSkillsToShow);

  return (
    <div className={styles.card} ref={containerRef}>
      <h3 className={styles.title}>Required Skills</h3>
      <div className={styles.skills_container}>
        {skillsList.slice(0, maxSkillsToShow).map((skill, index) => (
          <div key={index} className={styles.skill_item}>
            {skill}
          </div>
        ))}
        {remainingSkillsCount > 0 && (
          <div className={styles.remaining_skills}>{`+${remainingSkillsCount} more`}</div>
        )}
      </div>
    </div>
  );
};

export default Skillcard;


