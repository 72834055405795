import { useState, useEffect } from 'react';

const useJdParser = (jobDescription) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!jobDescription) return;

        const fetchData = async () => {
            setLoading(true);
            setError(null);

            const apiKey = 'AIzaSyBYPL_pUlXXn3iaOHp_ptjjg4XHGG45c2U';  
            const url = `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${apiKey}`;

            const payload = {
                contents: [{
                    parts: [
                        {
                            text: `You are an expert job description reader and extract information from it.\n\nJOB DESCRIPTION:\n'''\n${jobDescription}\n'''\n\nREAD THE ABOVE JOB DESCRIPTION AND FILL IN THE FOLLOWING INFORMATION IN THE BELOW FORMAT:\n'''\n{
                                "role_name": "",
                                "company_name": "",
                                "ideal_candidate_profile": {
                                    "profile_title1": "",
                                    "profile_title2": "",
                                    "profile_title3": "",
                                    "profile_title4": "",
                                    "profile_title5": ""
                                },
                                "required_skills": [],
                                "preferred_skills": [],
                                "minimum_experience": , # should be in number (float)
                                "maximum_experience": , # should be in number (float)
                                "highest education": "",
                                "locations": [], # only city names
                                "notice_period": , # should be number of days (float)
                                "min_ctc": , # should be in number (float)
                                "max_ctc": , # should be in number (float)
                            }\n\nInstruction:\n1. If you are not able to find any information, leave it blank.\n2. Don't change the format or add any extra information.\n3. Ensure the combined length of the strings in the "required_skills" and "preferred_skills" arrays is less than 200 characters when joined into a comma-separated string. Prioritize including the most important skills at the beginning of the string.\n4. In ideal_candidate_profile, write 5 different Naukri profile titles in the above format ranging from two to three lines for each. Refer to the example below for React JS developer:\n"React JS Developer with B.Tech/BE in Mechanical Engineering, currently living in Bangalore/Bengaluru"\n"Immediate Joiner. Actively looking for a job as React JS Developer. Total 6+ years of experience in React. Key skills are - React, Redux, Javascript, Typescript, Redux, Redux Toolkit, Context API, Material UI, Jest, Node js, Contentful, git."\n"Experienced React.js Developer Proficient in Next.js, Redux, and TypeScript. Expertise in Developing Dynamic User Interfaces with JavaScript, HTML, and CSS. Dedicated to Code Quality and Collaborative Development Through Git."\n\n'''\n`
                        }
                    ]
                }],
                generationConfig: {
                    response_mime_type: 'application/json'
                }
            };
            
            

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                const jsonResponse = await response.json();
                const parsedResponse = JSON.parse(jsonResponse.candidates[0].content.parts[0].text);
                setData(parsedResponse);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [jobDescription]);

    return { data, loading, error };
};

export default useJdParser;
