import React, { useState, useEffect } from "react";
import styles from "./Accordion.module.css";
import Naukricard from "../listingcard/Naukricard";
import Checkbox from "../checkbox/Checkbox";

function Accordion ({ categories, setData }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleCategory = (index) => {
    setActiveIndex(activeIndex === index ? 0 : index);
  };

  

  return (
    <div className={styles.container}>
      <div className={styles.accordion}>
        {categories.map((category, index) => (
          <div key={index} className={styles.category}>
            <button
              className={styles.categoryTitle}
              onClick={() => toggleCategory(index)}
            >
              {category.name}
              <div className={styles.categoryTitleBut}>View Pool</div>
            </button>
            {activeIndex === index && (
              <div className={styles.categoryContent}>
                <table className={styles.table}>
                  <thead></thead>
                  <tbody>
                    {category.items.map((item, itemIndex) => (
                      <tr key={itemIndex}>
                        <td>
                          {item.jsUserName} <Checkbox checked={item.checked} userId={item.jsUserId} setData={setData}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className={styles.cardList_container}>
        {categories[activeIndex]?.items?.map((item, i) => <Naukricard data={item} key={i} setData={setData} />)}
      </div>
    </div>
  );
};

export default Accordion;
