import React, { useEffect, useState } from 'react';
import styles from './Submissioncard.module.css'; 
import Progressbar from '../progressbar/Progressbar';
import edit from '../../assets/icons/svg/edit.svg';
import remove from '../../assets/icons/svg/delete.svg';
import more from '../../assets/icons/svg/more1.svg'
import { axiosPrivateCall, apiCall } from '../../constants';
import { ToastContainer, toast } from 'react-toastify';
import cup from '../../assets/icons/svg/cup.svg';




function SubmissionCard(props)  {
    let data = props.data;

    function formatDate(inputDateString) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const inputDate = new Date(inputDateString);
        return inputDate.toLocaleDateString('en-US', options);
    }

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        get_progress(data.current_progress, data.progress)
        // console.log(data.current_progress)
        // console.log(data.progress)
        // console.log(progress)
        
    }, [progress])

   

    function delete_item (id) {
        let payload = {_id: id}

        apiCall(
            "post",
            `/api/v1/submission/deleteSubmission`,
            payload,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
                toast.success("Submission Deleted Successfully!");
                props.setsudoUpdate((prev) => !prev);
            }
          });

    }

    function findIndexByString(array, searchString) {
        for (let i = 0; i < array?.length; i++) {
          if (array[i]['state'] === searchString) {
            return i; // Return the index when a match is found
          }
        }
        return -1; // Return -1 if no match is found
      }
      

    function get_progress(data, progressArr) {
        let i = findIndexByString(progressArr, data);

        if (i === 0) {
            setProgress(Math.ceil(((i + 1) / progressArr?.length) * 100));
        } else {
            setProgress(Math.ceil(((i + 1) / progressArr?.length) * 100));
        }
    }

    return (
        <div className={`${styles.container}`}>

            <div className={`${styles.left_container}`}>
                <div className={`${styles.data}`}>
                    <div className={`${styles.data_title}`}>Submission ID</div>
                    <div className={`${styles.data_value}`}>{data?.submission_id}</div>
                </div>
            </div>

            


            <div className={`${styles.mid_container}`}>

                <div className={`${styles.sub_container_1}`} >
                    <div className={`${styles.data_progress}`}>
                        <Progressbar progress={data?.current_progress == 'CLOSED' ? 100 : progress} baseColor={'none'} showMarking={true} showLevel={true} icon={cup}/>
                    </div>
                </div>
                
                

                <div className={`${styles.sub_container_2}`} >
                    <div className={`${styles.data}`}>
                        <div className={`${styles.data_title}`}>Current Status</div>
                        <div className={`${styles.data_value}`}>{(data?.current_progress == " ") ? 'Created' :  data?.current_progress }</div>
                    </div>

                    <div className={`${styles.data}`}>
                        <div className={`${styles.data_title}`}>Demand ID</div>
                        <div className={`${styles.data_value}`}>{data?.demand?.demand_id}</div>
                    </div>

                    <div className={`${styles.data}`}>
                        <div className={`${styles.data_title}`}>Candidate ID</div>
                        <div className={`${styles.data_value}`}>{data?.candidate?.candidate_id}</div>
                    </div>

                </div>
                
                
            </div>

            <div className={`${styles.right_container}`}>
                <div className={`${styles.buttons_container}`}>
                    <div className={`${styles.demand_buttons}`}
                    onClick={() => {props.setEditModal({show: true, data: data})}}
                    >
                        <img src={edit}/>
                    </div>
                    <div className={`${styles.demand_buttons}`} 
                    onClick={() => {delete_item(data._id)}}
                    >
                        <img src={remove}/>
                    </div>
                    <div className={`${styles.demand_buttons}`}><img src={more}/></div>

                </div>

            </div>

        </div>
    );
};

export default SubmissionCard;