import React, { useState } from "react";
import styles from "./Naukricard.module.css";
import Checkbox from "../checkbox/Checkbox";
import location from "../../assets/icons/svg/location_a1.svg";
import briefcase from "../../assets/icons/svg/briefcase_a1.svg";
import wallet from "../../assets/icons/svg/wallet_a1.svg";
import download from "../../assets/icons/svg/download.svg";
import profile from "../../assets/images/profile.png";

function Naukricard({ data, setData }) {
  const [expandKskills, setExpandKskills] = useState(false);
  const [expandskills, setExpandskills] = useState(false);
  const [expandProfile, setExpandsProfile] = useState(false);

  function printCtc(ctc) {
    let buffer = (ctc.lacs * 100000 + ctc.thousands * 1000) / 100000;
    return `${ctc.currency === "INR" ? "₹" : "$"} ${buffer.toFixed(2)} Lacs`;
  }

  function printDate(milliseconds) {
    const date = new Date(milliseconds);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();


  // Pad single-digit day and month with leading zero
  const dayString = day.toString().padStart(2, '0');
  const monthString = month.toString().padStart(2, '0');
  
    // Pad single-digit day with leading zero
   
  
    // Return the formatted date string
    return `${dayString}/${monthString}/${year}`;
  }

  function printCurrent(employment) {
    if (employment.current.designation && employment.current.organization) {
      return `${employment.current.designation} at ${employment.current.organization} `;
    } else if (
      employment.current.designation === "" &&
      employment.current.organization
    ) {
      return `Works at ${employment.current.organization} `;
    } else if (
      employment.current.designation &&
      employment.current.organization === ""
    ) {
      return `Works as ${employment.current.designation} `;
    } else {
      return `--`;
    }
  }

  function printPrevious(employment) {
    if (employment.previous.designation && employment.previous.organization) {
      return `${employment.previous.designation} at ${employment.previous.organization} `;
    } else if (
      employment.previous.designation === "" &&
      employment.previous.organization
    ) {
      return `Worked at ${employment.previous.organization} `;
    } else if (
      employment.previous.designation &&
      employment.previous.organization === ""
    ) {
      return `Worked as ${employment.previous.designation} `;
    } else {
      return `--`;
    }
  }

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.checkbox_wrapper}`}>
        <Checkbox checked={data?.checked} userId={data?.jsUserId} setData={setData} />
      </div>

      <div className={`${styles.info_container}`}>
        <div className={`${styles.name_wrapper}`}>{data?.jsUserName}</div>
        <div className={`${styles.data_wrapper}`}>
          <div className={`${styles.iconData}`}>
            <img src={briefcase} />
            <span>{`${data?.experience?.years}y ${data?.experience?.months}m`}</span>
          </div>
          <div className={`${styles.iconData}`}>
            <img src={wallet} />
            <span>{printCtc(data?.ctcinfo)}</span>
          </div>
          <div className={`${styles.iconData}`}>
            <img src={location} />
            <span>{data?.currentLocation}</span>
          </div>
        </div>

        <div className={`${styles.data_table}`}>
          <table className={styles.table}>
            <thead></thead>
            <tbody>
              <tr className={styles.data_row}>
                <th className={styles.data_label}>Current</th>
                <td className={styles.data_value}>
                  {printCurrent(data?.employment)}
                </td>
              </tr>

              <tr className={styles.data_row}>
                <th className={styles.data_label}>Previous</th>
                <td className={styles.data_value}>
                  {printPrevious(data?.employment)}
                </td>
              </tr>

              <tr className={styles.data_row}>
                <th className={styles.data_label}>Education</th>
                <td className={styles.data_value}>
                  {`${
                    data.education.ppg
                      ? `${data?.education?.ppg.course} in ${data.education.ppg.specialization} at ${data.education.ppg.institute} - ${data.education.ppg.year}`
                      : ""
                  }`}
                  {data.education.ppg ? (
                    <div className={styles.spacer} />
                  ) : null}

                  {`${
                    data.education.pg
                      ? `${data.education.pg.course} in ${data.education.pg.specialization} at ${data.education.pg.institute} - ${data.education.pg.year}`
                      : ""
                  }`}
                  {data.education.pg ? <div className={styles.spacer} /> : null}

                  {`${
                    data.education.ug
                      ? `${data.education.ug.course} in ${data.education.ug.specialization} at ${data.education.ug.institute} - ${data.education.ug.year} `
                      : ""
                  }`}
                </td>
              </tr>

              <tr className={styles.data_row}>
                <th className={styles.data_label}>Pref.Location</th>
                <td className={styles.data_value}>{data?.preferredLocations}</td>
              </tr>

              <tr className={styles.data_row}>
                <th className={styles.data_label}>Keyskills</th>
                <td
                  className={`${styles.data_value} ${
                    expandKskills ? styles.data_value_full : ""
                  }`}
                  onClick={() => setExpandKskills(!expandKskills)}
                >
                  {data?.keySkills?.split(",").join(" | ")}
                </td>
              </tr>

              <tr className={styles.data_row}>
                <th className={styles.data_label}>May Also Know</th>
                <td
                  className={`${styles.data_value} ${
                    expandskills ? styles.data_value_full : ""
                  }`}
                  onClick={() => setExpandskills(!expandskills)}
                >
                  {data?.focusedSkills?.split(",").join(" | ")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className={`${styles.profile_container}`}>
        <div className={`${styles.image_wrapper}`}>
          <img src={profile} />
        </div>

        <div className={`${styles.profile_description} ${
                    expandProfile ? styles.profile_description_full : ""
                  }`}
                  onClick={() => setExpandsProfile(!expandProfile)}>{data?.jobTitle}</div>

        <div className={`${styles.activity}`}>
          {`Active on: ${printDate(data?.activeDateMillis)}`}
        </div>

        <div className={`${styles.updated}`}>
          {`Modified on: ${printDate(data?.modifyDateMillis)}`}
        </div>
      </div>

      <div className={`${styles.tab_container}`}>
      <a href={data?.url} target="_blank" rel="noopener noreferrer">
        <button className={`${styles.but}`}>
          <img src={download} />
        </button>
      
      </a>
        
      </div>
    </div>
  );
}

export default Naukricard;


