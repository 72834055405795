import React, { useState } from "react";
import styles from "./Login.module.css";
import { axiosPublicCall, axiosAuthCall, apiCall } from "../../constants";
import Loadersmall from '../../components/loader/Loadersmall';
import dataStore from "../../store/store";

function Signin({ setSessionUser, setIsNewUser }) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const {setObjectData} = dataStore();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    const updatedErrors = { email: "", password: "" };

    if (!formData.email.trim()) {
      updatedErrors.email = "Username is required";
      isValid = false;
    }

    if (!String(formData.email.trim()).toLowerCase().match(emailRegex)) {
      updatedErrors.email = "Invalid Email";
      isValid = false;
    }

    if (!formData.password.trim()) {
      updatedErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(updatedErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      apiCall(
        "post",
        "/api/v1/user/signIn",
        formData,
        {},
        axiosPublicCall
      ).then((res) => {
        if (res) {
          localStorage.setItem("auth_token", res?.token);
          apiCall("get", "/api/v1/user/getRoles", null, {}, axiosAuthCall).then(
            (res) => {
              if (res) {
                setSessionUser({ logged_in: true, user_orgs: res });
                setObjectData('current_user', res);
                setIsLoading(false);
              }
            }
          );
        }
      });
    } else {
      console.log("Login failed. Please check the form.");
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.form_container}`}>
        <div className={`${styles.title_container}`}>
          <div className={`${styles.line_1}`}>ACCOUNT</div>

          <div className={`${styles.line_2_wrapper}`}>
            <div className={`${styles.line_2}`}>LOGIN</div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className={`${styles.login_container}`}>
          <div className={`${styles.username_container}`}>
            <label className={`${styles.form_label}`} htmlFor="email">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>{errors.email}</div>
          </div>

          <div className={`${styles.password_container}`}>
            <label className={`${styles.form_label}`} htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>{errors.password}</div>
          </div>

          <button type="submit" className={`${styles.button_1}`}>
            {!isLoading && <div className={`${styles.button_txt}`}>Login</div> }
            {isLoading && <Loadersmall/>}
          </button>

          <div className={`${styles.signin_link}`}>
            New Here?{" "}
            <span
              onClick={() => {
                setIsNewUser(true);
              }}
            >
              Sign Up
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signin;
