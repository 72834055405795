import React, { useState } from "react";
import styles from "./Login.module.css";
import { axiosPublicCall, apiCall } from "../../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/loader/Loader";
import Loadersmall from "../../components/loader/Loadersmall";

function Signup(props) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: "",
    firstname: "",
    lastname: "",
    mobile: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    username: "",
    firstname: "",
    lastname: "",
    mobile: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    const updatedErrors = {
      email: "",
      password: "",
      username: "",
      firstname: "",
      lastname: "",
      mobile: "",
    };

    if (!formData.email.trim()) {
      updatedErrors.email = "Email is required";
      isValid = false;
    }

    if (!String(formData.email.trim()).toLowerCase().match(emailRegex)) {
      updatedErrors.email = "Invalid Email";
      isValid = false;
    }

    if (!formData.username.trim()) {
      updatedErrors.username = "Username is required";
      isValid = false;
    }

    if (!formData.firstname.trim()) {
      updatedErrors.firstname = "First Name is required";
      isValid = false;
    }

    if (!formData.lastname.trim()) {
      updatedErrors.lastname = "Last Name is required";
      isValid = false;
    }

    if (!formData.mobile.trim()) {
      updatedErrors.mobile = "Mobile is required";
      isValid = false;
    }

    if (isNaN(formData.mobile.trim()) || formData.mobile.trim().length !== 10) {
      updatedErrors.mobile = "Invalid Mobile number";
      isValid = false;
    }

    if (!formData.password.trim()) {
      updatedErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(updatedErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true)
      apiCall(
        "post",
        "/api/v1/user/signUp",
        formData,
        {},
        axiosPublicCall
      ).then((res) => {
        if (res) {
          toast.success("User Created Successfully! Sign in");
          setIsLoading(false)
          props.setIsNewUser(false);
        }
      }).catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
    } 
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.form_container}`}>
        <div className={`${styles.title_container}`}>
          <div className={`${styles.line_1}`}>ACCOUNT</div>
          <div className={`${styles.line_2_wrapper}`}>
            <div className={`${styles.line_2}`}>SIGNUP</div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className={`${styles.login_container}`}>
          {/* Email field */}
          <div className={`${styles.username_container}`}>
            <label className={`${styles.form_label}`} htmlFor="email">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>{errors.email}</div>
          </div>

          {/* Username field */}
          <div className={`${styles.username_container}`}>
            <label className={`${styles.form_label}`} htmlFor="username">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>{errors.username}</div>
          </div>

          {/* First Name field */}
          <div className={`${styles.username_container}`}>
            <label className={`${styles.form_label}`} htmlFor="firstname">
              First Name
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>
              {errors.firstname}
            </div>
          </div>

          {/* Last Name field */}
          <div className={`${styles.username_container}`}>
            <label className={`${styles.form_label}`} htmlFor="lastname">
              Last Name
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>{errors.lastname}</div>
          </div>

          {/* Mobile field */}
          <div className={`${styles.username_container}`}>
            <label className={`${styles.form_label}`} htmlFor="mobile">
              Mobile
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>{errors.mobile}</div>
          </div>

          {/* Password field */}
          <div className={`${styles.password_container}`}>
            <label className={`${styles.form_label}`} htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className={`${styles.text}`}
            />
            <div className={`${styles.error_container}`}>{errors.password}</div>
          </div>

          <button type="submit" className={`${styles.button_1}`} >
            {!isLoading && <div className={`${styles.button_txt}`}>Sign Up</div>}
            {isLoading && <Loadersmall/>}
          </button>

          <div className={`${styles.signin_link}`}>
            Already a member?{" "}
            <span
              onClick={() => {
                props.setIsNewUser(false);
              }}
            >
              Sign In
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
