import React from "react";
import styles from "../modals.module.css"
import arrowright1 from "../../../assets/icons/svg/arrowright1.svg"
import { useState, useEffect } from "react";
import DynamicFormGenerator from "../../dynamicformgenerator/Dynamicformgenerator";
import { axiosPrivateCall } from "../../../constants";
import { ToastContainer, toast } from 'react-toastify';


function View(props) {

  const formFields = [
    { name: 'job_role', label: 'Role', type: 'text', placeholder: 'Enter the job role', isRequired: true },
    { name: 'job_description', label: 'Job Description', type: 'textarea', placeholder: 'Enter job description...', isRequired: true },
    // { name: 'minimum_ctc', label: 'Minimum CTC', type: 'text', placeholder: 'Enter minimum CTC', isRequired: true },
    // { name: 'maximum_ctc', label: 'Maximum CTC', type: 'text', placeholder: 'Enter maximum CTC', isRequired: true },
    { name: 'current_ctc', label: 'Current CTC', type: 'text', placeholder: 'Enter current CTC', isRequired: true },
    { name: 'minimum_experience', label: 'Minimum Experience', type: 'text', placeholder: 'Enter minimum experience', isRequired: true },
    { name: 'maximum_experience', label: 'Maximum Experience', type: 'text', placeholder: 'Enter maximum experience', isRequired: true },
    { name: 'skillset', label: 'Required Skills', type: 'taginput', placeholder: 'Enter required skills', isRequired: true },
    { name: 'no_of_positions', label: 'Candidates Required', type: 'text', placeholder: 'Enter number of candidates required', isRequired: true },
    { name: 'stake_holder', label: 'Recruiting For', type: 'text', placeholder: 'Enter recruiting for', isRequired: true },
    { name: 'assigned_to', label: 'Assign To', type: 'text', placeholder: 'Enter assigned to', isRequired: true },
    { name: 'due_date', label: 'Due Date', type: 'date', placeholder: 'Enter due date', isRequired: true },
    { name: 'location', label: 'Location', type: 'text', placeholder: 'Enter location', isRequired: true },
    { name: 'notice_period', label: 'Notice Period', type: 'text', placeholder: 'Enter notice period', isRequired: true },
    // Add more form fields as needed
  ];
      const [formState, setFormState] = useState(props.data);
      const [errors, setErrors] = useState({});

      function sanitize (datas, fields) {
        let buffer = fields.map((field) => field.name);
        let res = {};
        buffer.forEach((name) => res[name] = datas[name])
        return res;
      }


    
      const handleInputChange = (fieldName, value) => {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: '',
        }));
      };
    
      function validateField(fieldName, value) {
        let errorMessage = '';
    
        if (fieldName === 'current_ctc' || fieldName === 'minimum_experience' || fieldName === 'maximum_experience' || fieldName === 'no_of_positions') {
            // Check if value is a string before calling trim
            errorMessage = (typeof value === 'string' && isNaN(parseFloat(value.trim()))) ? "Invalid input. Kindly enter a valid numeric value." : '';
        }
    
        if (fieldName === 'skillset') {
            errorMessage = (value.length) ? '' : 'Required';
        }
    
        return errorMessage;
    }

      const validateForm = (formTemplate, formState, setErrors) => {
       
        let newErrors = {};
    
        formTemplate.forEach((field) => {
          if (field.isRequired && (formState[field?.name] === '' || formState[field?.name] === undefined)) {
            newErrors[field.name] = 'Required';
          } else {
            newErrors[field.name] = validateField(field.name, formState[field?.name]);
          }
        });
    
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
        }));

        return newErrors;
      
      };
      
      function isFormValid () {
        // Use await to wait for the promise returned by validateForm
        const updatedErrors = validateForm(formFields, formState, setErrors);
      
        // Check if there are no errors in the updated errors object
        return Object.values(updatedErrors).every(error => error === '');
      };
      
    
      const handleSubmit = () => {
        if (isFormValid()) {
          // Form is valid, proceed with submission
          let payload = sanitize (formState, formFields);
          payload._id = formState._id;

          axiosPrivateCall
            .post("/api/v1/demand/updateDemand", payload)
            .then((res) => {
              toast.success("Demand Updated Successfully!");
              props.onClose({show: false, data:''});
            })
            .catch((e) => {
            });
          // Add your network request logic here
        } else {
          // Form is not valid, handle accordingly (display errors, etc.)
          console.log('Form validation failed.', formState, errors);
        }
      };



    return (
        <div className={`${styles.container}`}>
          <div className={`${styles.title_container}`}>
              <div className={`${styles.line_1}`}>
                  Edit A
              </div>

              <div className={`${styles.line_2_wrapper}`}>
                  <div className={`${styles.line_2}`}>
                      DEMAND
                  </div>
              </div>
          </div>

          <div className={`${styles.form_container}`}>
              <DynamicFormGenerator formFields={formFields} 
                onInputChange={handleInputChange} 
                formData={formState} 
                setFormData={setFormState}
                errors={errors} 
              />
          </div>


          <div className={`${styles.submit_container}`}>
              <div className={`${styles.button_1}`} onClick={handleSubmit}>
                  <div>SUBMIT</div>
                  <img src={arrowright1} />
              </div>

              <div className={`${styles.button_2}`} onClick={() => {props.onClose({show: false, data:''})}}>
                  CANCEL
              </div>
              
          </div>
            
           
            
        </div>
    );
}

export default View;