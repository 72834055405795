import React from "react";
import styles from "../modals.module.css";
import arrowright1 from "../../../assets/icons/svg/arrowright1.svg";
import { useState, useEffect } from "react";
import DynamicFormGenerator from "../../dynamicformgenerator/Dynamicformgenerator";
import { ToastContainer, toast } from "react-toastify";
import { axiosPrivateCall, apiCall } from "../../../constants";
import Loadersmall from "../../loader/Loadersmall";
import dataStore from "../../../store/store";

function Create(props) {

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const formFields = [
    {
      name: "company_name",
      label: "Company Name",
      type: "text",
      placeholder: "Enter company name",
      isRequired: true,
    },
    {
      name: "poc_name",
      label: "POC",
      type: "text",
      placeholder: "Enter the name of Point of Contact",
      isRequired: true,
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Enter email",
      isRequired: true,
    },
    {
      name: "mobile",
      label: "Mobile",
      type: "text",
      placeholder: "Enter mobile number",
      isRequired: true,
    },
    {
      name: "stages",
      label: "Stages",
      type: "array",
      placeholder: "Enter stages",
      isRequired: true,
      edit: true,
    },
    // Add more form fields as needed
  ];
  // Add more form fields as needed

  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { stakeholders, updateArray } = dataStore();
  console.log(stakeholders);

  useEffect(() => {
    // Api Call to get form-template to Construst the form.
    // axiosPrivateCall(
    //     `/api/v1/demand/getDemandForm`
    // ).then((res) => {
    //     console.log(res.data);
    //     setFormFields(res.data);
    // }).catch((e) => {
    //     console.log(e);
    // });
  }, []);

  const handleInputChange = (fieldName, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  function validateField(fieldName, value) {
    let errorMessage = "";

    if (fieldName === "mobile_number") {
      if (isNaN(value.trim()) || value.trim().length !== 10) {
        errorMessage = "Invalid Mobile number";
      }
    }

    if (fieldName === "email") {
      if (!String(value.trim()).toLowerCase().match(emailRegex)) {
        errorMessage = "Invalid Email";
      }
    }

    if (fieldName === "stages") {
      errorMessage = value.length ? "" : "Required";

      value?.forEach((stage) => { 
        errorMessage = stage.state ? "" : "Required";
      })
    }

    return errorMessage;
  }

  const validateForm = (formTemplate, formState, setErrors) => {
    let newErrors = {};

    formTemplate.forEach((field) => {
      if (
        field.isRequired &&
        (formState[field?.name] === "" || formState[field?.name] === undefined)
      ) {
        newErrors[field.name] = "Required";
      } else {
        newErrors[field.name] = validateField(
          field.name,
          formState[field?.name]
        );
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));

    return newErrors;
  };

  function isFormValid() {
    // Use await to wait for the promise returned by validateForm
    const updatedErrors = validateForm(formFields, formState, setErrors);

    // Check if there are no errors in the updated errors object
    return Object.values(updatedErrors).every((error) => error === "");
  }

  const handleSubmit = () => {
    if (isFormValid()) {
      setIsLoading(true);

      let payload = { ...formState };

      apiCall(
        "post",
        `/api/v1/stakeholder/createStakeHolder`,
        payload,
        {},
        axiosPrivateCall
      ).then((res) => {
        if (res) {
          toast.success("Stakeholder Created Successfully!");
          props.onClose();
          updateArray("stakeholders", [res]);
          props.setsudoUpdate((prev) => !prev);
        }
      });
    } else {
      // Form is not valid, handle accordingly (display errors, etc.)
      console.log("Form validation failed.", formState, errors);
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title_container}`}>
        <div className={`${styles.line_1}`}>CREATE A</div>

        <div className={`${styles.line_2_wrapper}`}>
          <div className={`${styles.line_2}`}>STAKEHOLDER</div>
        </div>
      </div>

      <div className={`${styles.form_container}`}>
        <DynamicFormGenerator
          formFields={formFields}
          onInputChange={handleInputChange}
          formData={formState}
          setFormData={setFormState}
          errors={errors}
        />
      </div>

      <div className={`${styles.submit_container}`}>
        <div className={`${styles.button_1}`} onClick={handleSubmit}>
          {!isLoading && <div>SUBMIT</div>}
          {!isLoading && <img src={arrowright1}></img>}
          {isLoading && <Loadersmall />}
        </div>

        <div className={`${styles.button_2}`} onClick={props.onClose}>
          CANCEL
        </div>
      </div>
    </div>
  );
}

export default Create;
