import React, { useState, useEffect } from "react";
import styles from "./Searchbar.module.css";
import { ReactComponent as SearchIcon } from "../../assets/icons/svg/search.svg";
import { axiosPrivateCall, apiCall } from "../../constants";
import useDebounce from '../../hooks/useDebounce';

function Searchbar({ url, setListing, fields, setisLoader1, getListing, setIsFilterOn }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { debounce } = useDebounce();
  

  

  function Search(term) {
    if (setIsFilterOn)
    {
      setIsFilterOn(false)
    }
    setisLoader1(true)
    apiCall(
      "get",
      `/api/v1/${url}${term}${fields}`,
      null,
      {},
      axiosPrivateCall
    ).then((res) => {
      if (res) {
        let buffer = res?.hits?.map((hit) => hit?._source);
        if (buffer?.length) {
          setisLoader1(false)
          setListing([...buffer]);
        } else {
          getListing();
          setisLoader1(false)
        }
      }
    });
  }

  // Effect to trigger the debounced search when the searchTerm changes
  const handleInputChange = debounce((newValue) => {
    // Perform some time-consuming operation with the debounced value
    Search(newValue);
  }, 500);
  

  

  // Handle input change
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    handleInputChange(event.target.value);
  };

  return (
    <div className={`${styles.searchbar_container}`}>
      <SearchIcon className={styles.search_icon} />
      <input
        type="text"
        className={styles.search_input}
        placeholder="Type here to search"
        value={searchTerm}
        onChange={handleChange}
      />
    </div>
  );
}

export default Searchbar;
