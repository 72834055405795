import React, { useState, useRef, useEffect } from 'react';
import styles from './Taginput.module.css'; // Adjust the import path based on your project structure

const Taginput = (props) => {
  const [tagArray, setTagArray] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState(['React', 'JavaScript', 'CSS', 'HTML', 'Node.js']); 
  const inputRef = useRef(null);
  let formData = props.formData;
  let setFormData = props.setFormData;
  let fieldName = props.name;

  
  useEffect(() => {
    if (formData[fieldName])
    {
      setTagArray([...formData[fieldName]])
    }
    console.log('mounted')
  }, []);



  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: tagArray,
    }));
  }, [tagArray]);
  

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  const handleInputKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      // Check if the inputValue is not empty and does not already exist in the tagArray
      const trimmedValue = inputValue.trim();
      if (trimmedValue !== '' && !tagArray.includes(trimmedValue)) {
        setTagArray((prevTags) => [...prevTags, trimmedValue]);
        
        setInputValue('');
      }
      // Prevent space or enter from being entered into the input
      event.preventDefault();
    } else if (event.key === 'Backspace' && inputValue === '' && tagArray.length > 0) {
      // Remove the last tag when Backspace is pressed and input is empty
      setTagArray((prevTags) => prevTags.slice(0, -1));
      
    }
  };
  

  const handleTagRemove = (tagToRemove) => {
    setTagArray((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
    
  };

  const handleSuggestionClick = (suggestion) => {
    if (!tagArray.includes(suggestion)) {
      setTagArray((prevTags) => [...prevTags, suggestion]);
      
    }
    setInputValue('');
    inputRef.current.focus();
  };

  return (
      <div className={styles.container}>
        <div className={styles.tags_container}>
          {tagArray.map((tag) => (
            <div key={tag} className={styles.tag}>
              {tag}
              <button className={styles.cancel_button} onClick={() => handleTagRemove(tag)}>
                x
              </button>
            </div>
          ))}
        </div>

        

        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder="Type and press space..."
          className={styles.input_field}
        />

        {suggestions.length > 0 && inputValue.trim() !== '' && (
          <div className={styles.suggestions_dropdown}>
            {suggestions
              .filter((suggestion) => suggestion.toLowerCase().includes(inputValue.trim().toLowerCase()))
              .map((suggestion) => (
                <div
                  key={suggestion}
                  className={styles.suggestion_item}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
          </div>
        )}
        
        
      </div>
  );
};

export default Taginput;
