import React from "react";
import styles from "./Sidebutton.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosPrivateCall } from "../../constants";



function Logoutbutton(props) {

    const navigate = useNavigate();
    

    const signOutHandler = () => {
        // axiosPrivateCall.post('/api/v1/employee/logoutEmployee',{})
        // .then((res)=>{
        //     console.log(res); 
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("auth_token");
        //     navigate('/');})
        // .catch(e=>console.log(e));

        localStorage.removeItem("token");
        localStorage.removeItem("auth_token");
        navigate('/');
        window.location.reload();
    };

    const location = useLocation();
    let pathname = location.pathname;

    return (
        <>
            <div title='Logout' className={`${styles.icon_wrapper} ${props.to == pathname ? styles.active : ""}`} onClick={signOutHandler} >
                <img src={props.icon} className={`${styles.icon} `}/>
            </div>
        </>
    );
}

export default Logoutbutton;