import { create } from 'zustand';

const dataStore = create((set) => ({
  org_data: {},
  aggregate_data: {},
  current_user:{},
  stakeholders: [],
  employee: [],

  setArrayData: (arrayName, dataArray) => set((state) => ({ [arrayName]: [...dataArray] })),
  setObjectData: (objectName, dataObject) => set((state) => ({ [objectName]: {...dataObject}})),
  
  updateArray: (arrayName, dataArray) => set((state) => ({ [arrayName]: [...state[arrayName], ...dataArray]})),
  updateArrayObject: (objectName, dataObject) => set((state) => ({ [objectName]: {...state[objectName], ...dataObject}})),
}));

export default dataStore;


