import React, { useState, useEffect, useRef } from 'react';
import styles from './Taginput.module.css';

const SearchableSelect = ({ options, onInputChange, name, formData}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const selectRef = useRef(null);

  const filteredOptions = options?.filter(option =>
    option?.label?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  useEffect (() => {
    if (name === 'demand' || name === 'candidate' || name === 'stake_holder')
    {
      const filteredValueOptions = options?.filter(option => (option.value === formData[name]?._id));
      setSearchTerm(filteredValueOptions[0]?.label)
    } else {
      const filteredValueOptions = options?.filter(option => (option.value === formData[name]));
      console.log (filteredValueOptions[0]?.label)
      setSearchTerm(filteredValueOptions[0]?.label)
    }
    

  }, [options])

  

  const handleToggle = () => {
    setIsOpen(true);
  };

  const handleSelect = option => {
    setSelectedOption(option);
    setSearchTerm(option.label);
    onInputChange(name, option.value);
    setIsOpen(false);
  };

  const handleInputChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleClickOutside = e => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} ref={selectRef}>
      <div className="select-input" onClick={handleToggle}>
        <input
          type="text"
          value={searchTerm}
          className={styles.input_field_1}
          onChange={handleInputChange}
          placeholder="Search for an option..."
        />
      </div>
      {isOpen && (
        <div className={styles.suggestions_dropdown}>
          {filteredOptions.length ? filteredOptions?.map(option => (
            <div
              key={option.value}
              className={styles.suggestion_item}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          )) : options?.map(option => (
            <div
              key={option.value}
              className={styles.suggestion_item}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>

          ))}
        </div>
      )}

    </div>
  );
};

export default SearchableSelect;
