import React from "react";
import styles from "./Progressbar.module.css";
import marker from "../../assets/icons/svg/marker.svg";
import { motion } from "framer-motion";



function Progressbar(props) {

    let progress = props.progress;
    let showMarking = props.showMarking;
    let showLevel = props.showLevel;
    let icon = props.icon;
    let title = props.title;
    let baseColor = props.baseColor;
    
    return (
        <div className={`${styles.container}`}>
            {title && (<div className={`${styles.title_wrapper}`}>
                {title}
            </div>)}
            <div className={`${styles.progress_container}`}> 
                <div className={`${styles.baseline_wrapper}`}>
                    <div className={`${(baseColor === 'none') ? styles.baseline_none : styles.baseline}`}></div>
                    {showMarking && <div className={`${styles.baseline_mark}`}>
                        <div>0%</div>
                        {progress ? <div>100%</div> : <div></div>}
                    </div>}
                    
                </div> 
                
                {progress ?<div className={`${styles.progress_wrapper}`}>
                    <motion.div className={`${styles.progress}`}
                     style={{width: `${progress}%`}}
                     initial={{ width: 0, }}
                     animate={{ opacity: 1, width: `${progress}%` }}
                     transition={{ duration: 0.8 }}>

                     </motion.div>
                    {showMarking && <motion.div 
                    className={`${styles.progress_mark}`} 
                    style={{width: `${progress}%`}}
                    initial={{ width: 0,  }}
                    animate={{ opacity: 1, width: `${progress}%` }}
                    transition={{ duration: 0.75 }}>
                        <div>0%</div>
                        <div className={`${styles.mark}`}>
                            {showLevel && `${progress}%`}
                            {icon && <div className={`${styles.marker_container}`}>
                                <div className={`${styles.icon_container}`}>
                                    <img src={icon}/>
                                </div>

                                <div className={`${styles.indicator}`}>
                                    <img src={marker}/>
                                </div>
                            </div> }
                        </div>
                        
                    </motion.div> }
                </div> : null}
                
            </div>
        </div>
    );
}

export default Progressbar;