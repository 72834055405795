import React, { useEffect, useState } from "react";
import styles from "./Stascarddark.module.css";
import Bargraph from "../../components/bargraph/Bargraph";
import arrowright from "../../assets/icons/svg/arrowright.svg";
import dataStore from "../../store/store"


function Statscarddark() {

    const {aggregate_data} = dataStore();
    console.log(aggregate_data.total_demands)

    const [totDemand, setTotDemand] = useState(0);
    const [totFulDemand, setFulTotDemand] = useState(0);
    const [totSub, setTotSub] = useState(0);
    const [totFulSub, setTotFulSub] = useState(0);

    

    useEffect(() => {
        
        setTotDemand(aggregate_data?.total_demands);
        setFulTotDemand(aggregate_data?.total_demands_fulfilled);
        setTotSub(aggregate_data?.total_submissions);
        setTotFulSub(aggregate_data?.total_submissions_closed);
       
    },[aggregate_data]);

    return (
        <div className={`${styles.card_dark}`}>
                    <div className={`${styles.top_container} `} >
                        <div className={`${styles.title_container}`}>Submission Progress</div>
                        <div className={`${styles.action_container}`}>
                            <div><img className={`${styles.action_icon}`} src={arrowright}/><img src={arrowright}/></div>
                        </div>

                    </div>

                    <div className={`${styles.bottom_container} ${styles.dark_top_wrap}`}>
                        <Bargraph progress={(totSub && totDemand) ? Math.ceil((totSub / totDemand) * 100) : 1} color={'var(--primary-color)'} primaryStat={`${(totSub && totDemand) ? Math.ceil(((totSub / totDemand) * 100)) : 0}%`} secondaryStat={'Total Submission Created'}/>
                        <Bargraph progress={(totFulSub && totDemand) ? Math.ceil((totFulSub / totDemand) * 100) : 1} color={'var(--secondary-color)'} primaryStat={`${(totFulSub && totDemand) ? Math.ceil((totFulSub / totDemand) * 100) : 0}%`} secondaryStat={'Total Submission Closed'}/>
                        <Bargraph progress={(totFulSub && totSub) ? Math.ceil((totFulSub / totSub) * 100) : 1} color={'var(--light-color)'} primaryStat={`${(totFulSub && totSub) ? Math.ceil((totFulSub / totSub) * 100) : 0}%`} secondaryStat={'Submission Closure Percent'}/>

                    </div>
                    
                </div>
    )
};

export default Statscarddark;