import React, {useState} from 'react';
import styles from './Filterbutton.module.css'; 

function Filterbutton(props)  {
    let text = props.text;

    return (
        <div className={`${styles.container} ${props.isFilterOn ? styles.active  : null }`}
        onClick={() => { props.onClick()}}>
            {text}
        </div>
    );
};

export default Filterbutton;