import React from "react";
import styles from "./listing.module.css";
import placeholder from "../../assets/icons/svg/placeholder.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/svg/search.svg";
import add from "../../assets/icons/svg/add.svg";
import download from "../../assets/icons/svg/download.svg";
import more from "../../assets/icons/svg/more.svg";
import filter from "../../assets/icons/svg/filter.svg";
import Filterbutton from "../../components/filterbutton/Filterbutton";
import CandidateCard from "../../components/listingcard/Candidatecard";
import { createPortal } from "react-dom";
import Layoutmodal from "../../components/layoutmodal/Layoutmodal";
import { useState, useEffect } from "react";
import Create from "../../components/modals/candidate/Create";
import Edit from "../../components/modals/candidate/Edit";
import View from "../../components/modals/candidate/View";
import { axiosPrivateCall, apiCall } from "../../constants";
import Statscardlight from "../../components/statscard/Statscardlight";
import Statscarddark from "../../components/statscard/Statscarddark";
import Searchbar from "../../components/searchbar/Searchbar";
import Loader from "../../components/loader/Loader";

function Candidate() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [EditModal, setEditModal] = useState({ show: false, data: {} });
  const [ViewModal, setViewModal] = useState({ show: false, data: {} });
  const [candidateList, setCandidateList] = useState([]);
  const [fetchOptions, setFetchOptions] = useState({
    skip: 0,
    limit: 15,
    sort_field: "createdAt",
    sort_type: -1,
    search_field: "",
  });
  const [loader1, setisLoader1] = useState(false);
  const [sudoUpdate, setsudoUpdate] = useState('');

  let elastic_url = "candidate/searchCandidatesElastic?searchTerm=";
  let fields =
    "&searchFields=candidate_id,full_name,email,gender,prefered_location,current_location,skillset";

  useEffect(() => {
    getListing();
  }, [sudoUpdate]);

  useEffect(() => {}, [candidateList]);

  function getListing() {
    setisLoader1(true)
    apiCall(
      "get",
      `/api/v1/candidate/listCandidates?skip=0&limit=150&sort_type=desc&sort_field=candidate_id`,
      null,
      {},
      axiosPrivateCall
    ).then((res) => {
      if (res) {
        setCandidateList(res);
        setisLoader1(false)
      }
    });
  }

  // Function to fetch data in batches
  async function fetchDataBatch(offset, batchSize) {
    const response = await apiCall(
      "get",
      `/api/v1/candidate/listCandidates?skip=${offset}&limit=${batchSize}&sort_type=desc&sort_field=demand_id`,
      null,
      {},
      axiosPrivateCall
    );
    const data = response;
    return data;
  }

  // Generator function to fetch data in chunks
  async function* fetchAllData() {
    let offset = 0;
    const batchSize = 15;

    while (true) {
      const data = await fetchDataBatch(offset, batchSize);

      if (data.length === 0) {
        break; // No more data, exit the loop
      }

      offset += batchSize;
      yield data;
    }
  }

  // Function to convert data to CSV format
  function convertToCSV(data) {
    // Check if data is not an empty array
    if (data.length === 0) {
      return ''; // Return an empty string for an empty array
    }
  
    // Get the keys (header) from the first object in the array
    const header = Object.keys(data[0]);
  
    // Create the CSV content with header and rows
    const csvContent = [
      header.join(","),
      ...data.map((row) => header.map(key => {
        // If the property is an array, keep it as a single cell
        return Array.isArray(row[key]) ? `"${row[key].join(",")}"` : row[key];
      }).join(","))
    ].join("\n");
  
    return csvContent;
  }

  // Function to trigger the download
  function downloadCSV(csvContent) {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  // Event listener for the download button
  async function handleDownload() {
    const generator = fetchAllData();
    let allData = [];

    for await (const dataChunk of generator) {
      allData = allData.concat(dataChunk);
    }

    const csvContent = convertToCSV(allData);
    downloadCSV(csvContent);
  }

  return (
    <>
      {showAddModal &&
        createPortal(
          <Layoutmodal>
            <Create onClose={() => setShowAddModal(false)} setsudoUpdate={setsudoUpdate} />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {EditModal.show &&
        createPortal(
          <Layoutmodal>
            <Edit onClose={setEditModal} data={EditModal.data} setsudoUpdate={setsudoUpdate} />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {ViewModal.show &&
        createPortal(
          <Layoutmodal>
            <View onClose={setViewModal} data={ViewModal.data} />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      <div className={`${styles.container}`}>
        <div className={`${styles.stats_container}`}>
          <Statscardlight />
          <Statscarddark />
          <div className={`${styles.card_light_half}`}>
            <div className={`${styles.card_top_container}`}>
              <div className={`${styles.card_title_container}`}>
                <div className={`${styles.line_1}`}>EMPOWERING</div>
                <div className={`${styles.line_2_wrapper}`}>
                  <div className={`${styles.line_2}`}>RECRUITERS</div>
                </div>
              </div>
            </div>

            <div className={`${styles.card_bottom_container}`}>
              <div className={`${styles.card_tile_container}`}>
                <img src={placeholder} />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.mid_container}`}>
          <div className={`${styles.search_container}`}>
            <Searchbar
              setListing={setCandidateList}
              listing={candidateList}
              url={elastic_url}
              fields={fields}
              setisLoader1={setisLoader1}
              getListing={getListing}
            />

            <div className={`${styles.buttons_container}`}>
              <div
                className={`${styles.searchbar_button}`}
                onClick={() => setShowAddModal(true)}
                title="Add Candidate"
              >
                <img src={add} />
              </div>
              <div
                className={`${styles.searchbar_button}`}
                onClick={() => handleDownload()}
              >
                <img src={download} />
              </div>
              {/* <div className={`${styles.searchbar_button}`}>
                <img src={more} />
              </div> */}
            </div>
          </div>

          {/* <div className={`${styles.filter_container}`}>
                    
                    <div className={`${styles.filter_wrapper}`}><img src={filter}/>Filters</div>
                    
                    <Filterbutton text='This week'/>
                    <Filterbutton text='Assigned to me'/>
                    <Filterbutton text='Recently added'/>
                    <Filterbutton text='Featured'/>
                </div> */}
        </div>

        <div className={`${styles.listing_container}`}>
        {loader1 && <Loader/>}
          {!loader1 && candidateList.map((candidate) => (
            <CandidateCard
              key={candidate._id}
              setEditModal={setEditModal}
              setViewModal={setViewModal}
              data={candidate}
              setsudoUpdate={setsudoUpdate}
            />
            
          ))}
          {!candidateList.length && (<div className={styles.text_div}>Currently no candidate in your collection.</div>) }
          
          
        </div>
      </div>
    </>
  );
}

export default Candidate;
