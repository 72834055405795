import React, { useState, useRef, useEffect } from 'react';
import styles from './Taginput.module.css'; // Adjust the import path based on your project structure
import add from "../../assets/icons/svg/add.svg"

const Arrayinput = ({ formData, setFormData, name, label, edit }) => {
    const defaultLevelDetail = {
      state: "",
      remarks:"",
      status:"",
    };
  
    const [tagArray, setTagArray] = useState([defaultLevelDetail]);

    useEffect(() => {
        if (formData[name])
        {
          setTagArray([...formData[name]])
        }
    }, []);


    useEffect(() => {
        setFormData((prevState) => ({
          ...prevState,
          [name]: tagArray,
        }));
    }, [tagArray]);
  
      
  
    const handleInputChange = (event, index, field) => {
      setTagArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index][field] = event.target.value;
        return newArray;
      });
    };
  
    const handleRemoveItem = (index) => {
      setTagArray((prevArray) => prevArray.filter((_, i) => i !== index));
    };

    const addField = () => {
        setTagArray((prevArray) => [...prevArray, { ...defaultLevelDetail }]);
    };
  
    return (
      <>
         <label className={`${styles.field_label_1}`}>
            <div>
                {label}
            </div> 

            {edit && (<div className={`${styles.field_add}`} onClick= {addField}>
                <img src={add}/>
            </div>)}
        </label> 

        <div className={styles.container_1}>
          {tagArray.map((item, index) => (
            <div key={index} className={styles.field_container}>
              <div className={styles.top_container_1}>
                <input
                  type="text"
                  value={item.state}
                  onChange={(e) => handleInputChange(e, index, "state")}
                  placeholder="Enter Level Name"
                  className={styles.input_field_1}
                  disabled={!edit}
                />

              
                {edit && <div className={styles.close_wrap} onClick={() => handleRemoveItem(index)}><img className={styles.close} src={add}/></div>}
              
              </div>
  
              
  
            </div>
          ))}
        </div>
      </>
    );
  };
  
  export default Arrayinput;