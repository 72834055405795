import React, { useEffect } from "react";
import styles from "./SourcingConsole.module.css";
import { useState } from "react";
import Accordion from "../../../components/accordion/Accordion";


function SourcingConsole({data, setData}) {

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const selectedItems = data.flatMap(category => 
      category.items.filter(item => item.checked)
    );
    setSelected(selectedItems);
  }, [data]);

  function deployImporter () {

    if (selected.length) {
      const event = new CustomEvent("Sh-Base-Msg", {
        detail: {action: "IMPORT", payload: selected}
      });
      document.dispatchEvent(event);
    }

  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.top_container}`}>
        <div>{`Number of profile selected: ${selected.length}`}</div>
        {/* <button onClick={deployImporter} title="Import profiles to Scout Hawk">Import</button> */}
      </div>

      <div className={`${styles.bottom_container}`}>
        <div className={`${styles.bottom_left_container}`}>
          <Accordion categories={data} setData={setData} />
        </div>
        <div className={`${styles.bottom_right_container}`}></div>
      </div>
        
    </div>
  );
}

export default SourcingConsole;